import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44')
];

export const server_loads = [0];

export const dictionary = {
		"/(home)": [~2],
		"/about": [~3],
		"/authentication": [~4],
		"/blog": [~5],
		"/blog/[slug]": [~6],
		"/brand": [~7],
		"/brochures": [~8],
		"/brochures/[slug]": [~9],
		"/careers": [~10],
		"/contact": [~11],
		"/customer-stories": [~12],
		"/customer-stories/[slug]": [~13],
		"/data-sheets": [~14],
		"/data-sheets/[slug]": [~15],
		"/events": [~16],
		"/industries": [~17],
		"/industries/[slug]": [~18],
		"/leaders": [~19],
		"/leaders/[slug]": [~20],
		"/legal/[slug]": [~21],
		"/news": [~22],
		"/news/[slug]": [~23],
		"/outbound": [~24],
		"/podcasts": [~25],
		"/podcasts/[slug]": [~26],
		"/press": [~27],
		"/press/[slug]": [~28],
		"/reports": [~29],
		"/reports/[slug]": [~30],
		"/research": [~31],
		"/security": [~32],
		"/sitemap": [~33],
		"/technologies": [~34],
		"/technologies/[slug]": [~35],
		"/threats": [~36],
		"/threats/[slug]": [~37],
		"/topics/[slug]": [38],
		"/videos": [~39],
		"/videos/[slug]": [~40],
		"/webinars": [~41],
		"/webinars/[slug]": [~42],
		"/white-papers": [~43],
		"/white-papers/[slug]": [~44]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';